<template>
    <div>
        <Header/>
        <div class="ticket-info">
            <div v-if="!status">
                Загрузка...
            </div>
            <div v-else>
                <p>Статус по заявке {{ ticket }}</p>
                <p><span class="ticket-info__status">{{ message }}</span></p>
                <p v-if="status === 'attention'">
                    Свяжитесь с нами по телефону
                    <a href="tel:+74951337563">
                        8 (495) 133 75 63
                    </a>
                </p>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import getTicket from '@index/api/ticket'
import Header from '@index/components/common/header/Header'
import Footer from '@index/components/common/footer/Footer'

import './ticket-info.scss';

export default {
    data() {
        return {
            status: '',
            ticket: ''
        }
    },
    async mounted() {
        const data = await getTicket(this.$route.params.uid);

        this.ticket = data.ticket
        this.status = data.status
    },
    computed: {
        message() {
            switch (this.status) {
                case 'success':
                    return 'подписка отменена';
                case 'attention':
                    return 'требует уточнений';
                case 'notFound':
                    return 'не найдено';
                default:
                    return 'в работе'
            }
        }
    },
    components: {
        Header,
        Footer,
    }
}
</script>