<template>
    <footer class="footer">
        <h1 class="footer__title">
            Контактная информация
        </h1>
        <ul class="footer__contacts">
            <li class="footer-contact footer-contact_phone">
                <div>
                    <p class="footer-contact__title">
                        Телефон
                    </p>
                    <p class="footer-contact__desc">
                        <a href="tel:+74951337563">
                            8 (495) 133 75 63
                        </a>
                    </p>
                </div>
            </li>
            <li class="footer-contact footer-contact_mail">
                <div>
                    <p class="footer-contact__title">
                        Эл. почта
                    </p>
                    <p class="footer-contact__desc">
                        <a href="mailto:support@dengatyt.pw">support@dengatyt.pw</a>
                    </p>
                </div>
            </li>
            <li class="footer-contact footer-contact_address">
                <div>
                    <p class="footer-contact__title">
                        Адрес
                    </p>
                    <p class="footer-contact__desc">
                        125047, Г.МОСКВА, УЛ 1-Я ТВЕРСКАЯ-ЯМСКАЯ,Д. 12,ПОМЕЩ. 1, ОФИС 2/002
                    </p>
                </div>
            </li>
        </ul>
        <div class="footer__desc" v-html="text"></div>
        <a class="logo" href="/"></a>
    </footer>
</template>

<script>
import './footer.scss'
import Cookies from 'js-cookie'

export default {
    name: 'Footer',
    watch: {
        '$route'() {
            this.$forceUpdate()
        }
    },
    computed: {
        text() {
            if (this.$route.name === 'Main' && this.$DICTIONARY.footerTextIndex)
                return this.$DICTIONARY.footerTextIndex

            if (!Cookies.get('sbg-in'))
                return this.$DICTIONARY.footerTextFirst || this.$DICTIONARY.footerText

            return this.$DICTIONARY.footerText
        }
    }
}
</script>