<template>
  <div>
    <MobileMenu
      v-if="menu"
      @close="menu=false"
    />
    <header class="header">
        <div class="header__content">
          <div>
              <a href="/" class="logo"></a>
          </div>
            <div class="header__desc">
                <a href="tel:+74951337563" class="header__phone">
                    8 (495) 133 75 63
                </a>
            </div>
            <div class="header__desc">
                <router-link v-if="!isAuth" to="/login" class="header__status">Войти</router-link>
                <a v-else href="/lk" class="header__status">{{ fullName }}</a>
            </div>
            <div class="header__mobile">
                <span class="header__burger" @click="menu=true"><span></span></span>
            </div>
        </div>
    </header>
  </div>
</template>

<script>
import './header.scss';
import {mapGetters} from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      menu: false
    };
  },
  computed: {
      ...mapGetters({
          user: 'application/user'
      }),
      isAuth() {
          return !!this.user.contactData.id
      },
      fullName() {
          return [this.user.contactData?.firstname, this.user.contactData?.lastname].join(' ').trim() || 'Личный кабинет'
      }
  },
  methods: {
    openMenu() {
      this.menu = true;
    },
  },
  components: {
    MobileMenu: () => import('./MobileMenu'),
  }
};
</script>
